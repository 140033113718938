/**
 * project: 0600_katy-tndr
 * Created by EBiermann on 02.05.2021.
 */


"use strict";

import {gsap} from "gsap/all";
import delegate from "es6-delegate/delegate";

import Events from "../../tndr.events";
import * as Utils from "../../utilities/Utilities";
import BaseClass from "../../classes/BaseClass";
import Init from "../../classes/Init";

class Princess extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this.index = null;
		this.visible = false;

		this.screenBackground = this._element.querySelectorAll('.screen__background');
		this.screenElementProfile = this._element.querySelector('.profile');

		this._init();
	}

	//public
	getInstance() {
		return this;
	}

	isCurrentScreen() {
		return this.visible;
	}

	getIndex() {
		return this.index;
	}

	/**
	 *
	 * @returns {Promise<void>}
	 */
	out() {
		return this._out();
	}


	in() {
		return this._in();
	}

	//private
	_init() {
		this.visible = this._element.classList.contains('screen--visible');
		this.index = this._element.dataset.index;

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		//delegate(this._element, 'click', '.button--toolbar', this._onClick.bind(this));
	}

	async _out() {

		await gsap.timeline({
			onComplete: () => {
				console.log("on complete screen");
				if (this.visible) {
					this.visible = false;
					this.element.classList.remove('screen--visible');
				}
			}
		})
			.to(this.screenBackground, {
				opacity:0,
				duration: 0.15
			})
			.to(this.screenElementProfile, {
				yPercent: 10,
				opacity:0,
				duration: 0.3
			});


	}

	async _in() {
		this.element.scrollTop = 0;
		await gsap.timeline({
			onStart: () => {
				if (!this.visible) {
					this.visible = true;
					this.element.classList.add('screen--visible');
				}
			}
		})
			.to(this.screenBackground, {
				opacity:1,
				duration: 0.15
			})
			.to(this.screenElementProfile, {
				yPercent: 0,
				opacity:1,
				duration: 0.3
			});


	}
}

export default Princess;