/**
 * project: 0600_katy-tndr
 * Created by EBiermann on 30.04.2021.
 */


"use strict";

import {gsap} from "gsap/all";
import delegate from "es6-delegate/delegate";

import Events from "../tndr.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class Alert extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this.localstorage = window['localStorage'];

		this.background = this._element.querySelector('.alert__background');
		this.content = this._element.querySelector('.alert__content');
		this.type = this._element.dataset.type;

		this._tl = null;

		this._init();

	}

	//public

	//private
	_init() {

		let duration = 1800;
		if (this.type == 'match') {
			duration = 2300;
		}
		if (this.type == 'wtf') {
			duration = 4500;
		}

		this._tl = gsap.timeline({
			//repeat: 1,
			//yoyo: true,
			ease: 'power3.inOut',
			paused: true,
			onComplete: () => {
				setTimeout(() => {
					this._hide();
				}, duration);
			}

		});

		this._tl
			.set(this._element, { display: 'flex'})
			.to(this.background, {
				opacity: 1,
				duration: 0.2
			})
			.fromTo(this.content, {
				opacity: 0,
				y: '-10rem'
			}, {
				opacity: 1,
				y: 0,
				duration: 0.2
			});

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		delegate('click', '[data-action="alert"][data-type="'+ this.type +'"]', this._onClick.bind(this));
		window.addEventListener(Events.tndr.match, this._onMatch.bind(this));
		window.addEventListener(Events.tndr.wtf, this._onWtf.bind(this));
		//window.addEventListener(Events.layout.mutation.oberserved, this._onResize.bind(this));
		//window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
	}

	_hide() {
		this._tl.reverse();
	}

	_show() {
		this._tl.play();
	}

	_onClick(evt) {
		evt.preventDefault();
		this._show();
	}

	async _onMatch(evt) {
		if (this.type == 'match') {
			let userid = evt.detail.userid;
			const response = await fetch('/app/match/'+parseInt(userid));
			const html = await response.text();
			this.content.innerHTML = html;
			this._show();
		}
	}

	_onWtf(evt) {
		evt.preventDefault();
		if (this.type == 'wtf') {
			if (!this.localstorage.getItem('wtf-shown')) {
				this._show();
				this.localstorage.setItem('wtf-shown', true);
			}
			else {
				console.log("wtf already shown");
			}
		}
	}
}

export default Alert;