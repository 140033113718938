/**
 * project: 0600_katy-tndr
 * Created by EBiermann on 04.05.2021.
 */

"use strict";

import Events from "../tndr.events";
import Config from "../tndr.config";

class SessionHandler {
	constructor() {
		this.localstorage = window['localStorage'];
		this.sessionId = null;
		this._init();
	}

	//public

	getSessionId() {
		return this.sessionId;
	}

	/**
	 *
	 * @returns {Promise|*}
	 */
	getMessageCount() {
		return this._getMessageCount();
	}

	/**
	 *
	 * @param choice
	 * @param userid
	 * @returns {Promise<*>}
	 */
	setChoice(choice, userid) {
		return this._setChoice(choice, userid);
	}

	reset() {
		this._resetSession();
	}

	//private

	_init() {
		this.localstorage.removeItem('wtf-shown');
		
		if (this.localstorage && !this.localstorage.getItem('tndr-uid')) {
			let newTndrUid = Date.now();
			this.sessionId = newTndrUid;
			this.localstorage.setItem('tndr-uid', this.sessionId);
			window.tndruid = newTndrUid;
			console.log("create new storage id", newTndrUid);
		}
		else if (this.localstorage && this.localstorage.getItem('tndr-uid')) {
			this.sessionId = this.localstorage.getItem('tndr-uid');
			window.tndruid = this.sessionId;
			console.log("used old storage id", this.sessionId);
		}

		this._createSession(this.sessionId);

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		//window.addEventListener(Events.layout.mutation.oberserved, this._onLayoutMutationOberserved.bind(this));
	}

	_resetSession() {
		this.localstorage.removeItem('tndr-uid');
		window.tndruid = false;
		this.sessionId = null;
		this.localstorage.removeItem('wtf-shown');
		document.location.reload();
	}

	_createSession(localstorageId) {
		this._post('/app/session/create', {
			'localstorageId': localstorageId,
			'useragent': navigator.userAgent
		});
	}

	/**
	 *
	 * @returns {Promise|*}
	 * @private
	 */
	_getMessageCount() {
		return new Promise((resolve, reject) => {
			this._post('/app/session/messagecount', {
				'localstorageId': this.sessionId
			}).then(json => {
				let count = json.count;
				resolve(count);
			}).catch(err => {
				console.log(err);
				reject(0);
			});
		});
	}

	/**
	 *
	 * @param choice
	 * @param userid
	 * @returns {Promise<any>}
	 * @private
	 */
	_setChoice(choice, userid) {
		return this._post('/app/session/choice', {
			'localstorageId': this.sessionId,
			'choice': choice,
			'userid': userid
		});
	}

	async _post(url, data) {
		// Default options are marked with *
		const response = await fetch(url, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json'
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
			body: JSON.stringify(data) // body data type must match "Content-Type" header
		});
		return response.json(); // parses JSON response into native JavaScript objects

		/**
		 postData('https://example.com/answer', { answer: 42 }).then(data => {
                               console.log(data); // JSON data parsed by `data.json()` call
                        });
		 **/
	}

}

export default SessionHandler;