/**
 * project: 0600_katy-tndr
 * Created by EBiermann on 02.05.2021.
 */

"use strict";

import {gsap} from "gsap/all";
import delegate from "es6-delegate/delegate";

import Events from "../tndr.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

import Princess from './Screens/Princess';
import Users from './Screens/Users';
import Messages from './Screens/Messages';

class Screens extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this.screenElements = this._element.querySelectorAll('.screen');
		this.screens = {};

		this._init();

	}

	//public
	getInstance() {
		return this;
	}

	/**
	 *
	 * @returns {null}
	 */
	getCurrentScreen() {
		return this._getCurrentScreen();
	}

	//private
	_init() {
		this._initScreens();
		this._attachEventsAndListeners();
	}

	_initScreens() {
		let screen = null;
		this.screenElements.forEach((item, idx) => {
			switch(item.dataset.screen) {
				case 'princess':
					screen = new Princess(item);
					break;
				case 'users':
					screen = new Users(item);
					break;
				case 'messages':
					screen = new Messages(item);
					break;
				default:
					screen = null;
					break;
			}

			if (screen) {
				this.screens[item.dataset.screen] = screen;
			}
		});
	}

	_attachEventsAndListeners() {
		//delegate(this._element, 'click', '.button--toolbar', this._onClick.bind(this));

		window.addEventListener(Events.screens.out, this._onScreensOut.bind(this));
		window.addEventListener(Events.screens.in, this._onScreensIn.bind(this));
	}

	_getCurrentScreen() {
		let currentScreen = null;
		Object.entries(this.screens).forEach(([key, screen])=>{
			if (screen.isCurrentScreen()) {
				currentScreen = screen;
			}
		})

		return currentScreen;
	}

	_onScreensOut(evt) {
		const currentScreen = this._getCurrentScreen();

		if (currentScreen) {
			currentScreen.out().then(() => {
				//await ...
				Utils.DispatchEvent.dispatch(Events.screens.in, {
					nextScreen: evt.detail.nextScreen,
					nextIndex: evt.detail.nextIndex
				});
			});
		}
	}

	_onScreensIn(evt) {
		this.screens[evt.detail.nextScreen].in();
	}
}

export default Screens;