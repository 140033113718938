/**
 * project: General
 * Created by EBiermann on 01.10.2019.
 */

import Events from "../tndr.events";
import * as Utils from "../utilities/Utilities";

class IntersectionHandler {
	constructor(rootMargin, rootTreshold) {
		this._observer = null;
		this._items = [];
		this._itemsVisibleFrom = [];
		this._itemsWatchChanges = [];

		this._observerRootMargin = rootMargin || '0%';
		this._observerRootThreshold = rootTreshold || [0, 0.02, 0.05, 0.1, 0.2]; //0%, 2%, 5%, 10%, 20%
		//const observerRootThreshold = [0, 0.02, 0.05, 0.1, 0.2, 0.5, 0.8, 0.9, 0.95]; //0%, 2%, 5%, 10%, 20%

		this._init();
	}

	//public
	attach(element, visibleFrom, watchChanges) {
		if (visibleFrom !== undefined && visibleFrom !== null) {
			this._items.push(element);
			this._itemsVisibleFrom.push(visibleFrom);
			this._itemsWatchChanges.push(watchChanges ? watchChanges : false);
		}


		this._observer.observe(element);
	}

	detach(element) {
		const idx = this._items.indexOf(element);
		if (idx > -1) {
			this._items.splice(idx, 1);
			this._itemsVisibleFrom.splice(idx, 1);
		}
		this._observer.unobserve(element);
	}
	
	getInstance() {
		return this;
	}

	//private

	_init() {
		this._observer = new IntersectionObserver(this._onIntersectionOberserved.bind(this), {
			//root: app.lifecycle.content,
			rootMargin: this._observerRootMargin,
			threshold: this._observerRootThreshold
		});
	}

	_onIntersectionOberserved(intersectionOberserverEntries) {
		let target, idx, minValue, watchChanges;

		intersectionOberserverEntries.forEach((entry) => {
			target = entry.target;
			idx = this._items.indexOf(target);

			minValue = 10;
			if (idx !== -1) {
				minValue = this._itemsVisibleFrom[idx];
				watchChanges = this._itemsWatchChanges[idx];
			}

			if (Math.floor(entry.intersectionRatio * 100) > minValue || Math.floor(entry.intersectionRatio * 100) < 0) {
				if (!target.classList.contains('in-view')) {
					target.classList.add('in-view');

					Utils.DispatchEvent.dispatch(Events.inview.in, {
						element: target,
						intersectionRatio: (entry.intersectionRatio * 100),
						intersectionMinValue: minValue,
						oberserverEntry: entry
					}, target, false, true);
				}

				if (watchChanges) {
					Utils.DispatchEvent.dispatch(Events.inview.change, {
						entry: entry,
						element: target,
						intersectionRatio: (entry.intersectionRatio * 100),
						intersectionMinValue: minValue,
						oberserverEntry: entry
					}, target, false, true);
				}

			} else {
				if (target.classList.contains('in-view')) {
					target.classList.remove('in-view');
					Utils.DispatchEvent.dispatch(Events.inview.out, {
						element: target,
						intersectionRatio: (entry.intersectionRatio * 100),
						intersectionMinValue: minValue,
						oberserverEntry: entry
					}, target, false, true);
				}
			}
		});
	}
}

export default IntersectionHandler;