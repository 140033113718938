/**
 * project: 0600_katy-tndr
 * Created by EBiermann on 30.04.2021.
 */


"use strict";

import {gsap} from "gsap/all";
import delegate from "es6-delegate/delegate";

import Events from "../tndr.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class Toolbar extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this.buttons = this._element.querySelectorAll('.button--toolbar');
		this.messageButton = this._element.querySelector('.button--toolbar[data-screen="messages"]');
		this.screensElement = document.querySelector('.app__screens');
		this.screensInstance = null;

		this._init();

	}

	//public
	getInstance() {
		return this;
	}

	//private
	_init() {
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.preload.begin, this._onPreloadBegin.bind(this));
		window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
		window.addEventListener(Events.tndr.match, this._onTndrMatch.bind(this));



		delegate(this._element, 'click', '.button--toolbar', this._onClick.bind(this));
	}

	_onClick(evt, element) {
		evt.preventDefault();

		if (!this.screensInstance) return false;


		let screenIndex = element.dataset.index;
		let screenClass = element.dataset.screen;

		let currentScreen = this.screensInstance.getCurrentScreen();
		if (currentScreen) {

			this.buttons.forEach((item, idx) => {
				if (item.dataset.index == currentScreen.getIndex()) {
					item.classList.remove('button--active');
				}

				if (item.dataset.index == screenIndex) {
					item.classList.add('button--active');
				}
			})

			//dispatch screen event with params

			Utils.DispatchEvent.dispatch(Events.screens.out, {
				nextScreen: screenClass,
				nextIndex: screenIndex
			});
		}

		return false;
	}

	_onPreloadBegin(evt) {
	}

	_onPreloadFinished(evt) {
		this.screensInstance = app.handlers.component.getComponentInstanceByElement( this.screensElement, 'Screens');
		this._updateMessageCount();
	}

	_onTndrMatch(evt) {
		this._updateMessageCount();
	}

	_updateMessageCount() {
		window.app.handlers.session.getMessageCount().then(count => {
			console.log("new messageocunt", count);

			if (count > 0) {
				this.messageButton.classList.add('button--with-indicator');
				this.messageButton.querySelector('.button__indicator').textContent = count;
			}
			else {
				this.messageButton.classList.remove('button--with-indicator');
			}
		});
	}
}

export default Toolbar;