/**
 * project: 0600_katy-tndr
 * Created by EBiermann on 30.04.2021.
 */


"use strict";

import {gsap} from "gsap/all";
import delegate from "es6-delegate/delegate";

import Events from "../tndr.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class Intro extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this.backgroundItems = this._element.querySelectorAll('.intro__background');
		this.logo = this._element.querySelector('.intro__logo');
		this.svg = this.logo.querySelector('svg');
		this.theone = this.svg.querySelector('#theone');
		this.title = this.logo.querySelector('h1');
		this.subline = this.logo.querySelector('span');


		this._tl = null;



		this._init();

	}

	//public

	//private
	_init() {
		let duration = 3, startColor = 'rgba(210,79,157,1)', endColor = 'rgba(165,50,238,1)';
		console.log("init intro", this._element);

		this._tl = gsap.timeline({
			//repeat: 1,
			//yoyo: true,
			ease: 'power3.inOut',
			paused: true
		});

		this._tl
			.fromTo(this._element, {
				background: 'linear-gradient(135deg, rgba(210,79,157,1) 0%, rgba(165,50,238,1) 100%)',
			},{
				background: 'linear-gradient(135deg, rgba(165,50,238,1) 0%, rgba(210,79,157,1) 100%)',
				duration: duration
			})
			.to(this.logo, {
				scale: 1,
				ease: 'back.out(3)',
				duration: 0.6
			}, 0.5)
			.to(this.backgroundItems, {
				opacity: 1,
				stagger: { // wrap advanced options in an object
					each: 0.3,
					from: 'random',
					ease: 'power2.inOut'
					//repeat: -1 // Repeats immediately, not waiting for the other staggered animations to finish
				},
				duration: 0.6
			}, 0.9)
			.to(this.title, {
				y: 0,
				opacity: 1,
				duration: 0.3,
				ease: 'power2.out'
			}, 1)
			.to(this.subline, {
				y: 0,
				opacity: 1,
				duration: 0.3,
				ease: 'power2.out'
			}, 1)
			.to(this.svg, {
				y: 0,
				scale: 1,
				opacity: 1,
				duration: 0.15,
				ease: 'back.out(3)'
			}, 1.3)
			.to(this.theone, {
				'--theone-opacity': '1',
				duration: 0.2
			},  1.8)
			.to(this.logo, {
				scale: 4,
				opacity: 0,
				ease: 'back.in(3)',
				duration: 0.6
			}, 3.5)
			.to(this.backgroundItems, {
				scale: 2,
				opacity: 0,
				stagger: { // wrap advanced options in an object
					each: 0.15,
					from: 'random',
					ease: 'power2.in'
					//repeat: -1 // Repeats immediately, not waiting for the other staggered animations to finish
				},
				duration: 0.3
			}, 4)
			.to(this._element, {
				opacity: 0,
				duration: 0.3,
				display: 'none'
			},  4.2);


		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		//window.addEventListener(Events.layout.resize.did, this._onResize.bind(this));
		//window.addEventListener(Events.layout.mutation.oberserved, this._onResize.bind(this));
		window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
	}

	_onPreloadFinished(evt) {
		this._tl.play();
	}
}

export default Intro;