/**
 * project: 0600_katy-tndr
 * Created by EBiermann on 04.05.2021.
 */

"use strict";

import {gsap} from "gsap/all";
import delegate from "es6-delegate/delegate";

import Events from "../tndr.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class Profile extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this.objectId = null;

		this._init();

	}

	//public

	//private
	_init() {
		this.objectId = parseInt(this._element.dataset.id);
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		delegate(this._element, 'click', '.profile__control--no', this._onClickNo.bind(this));
		delegate(this._element, 'click', '.profile__control--yes', this._onClickYes.bind(this));
	}

	async _onClickNo(evt, element) {
		let profile = document.querySelector('.profile[data-id="'+this.objectId+'"]');
		profile.dataset.choice = 'no';
		document.querySelector('.user-overlay').style.display = 'none';
		//document.querySelector('.users__item[data-id="'+this.objectId+'"]').style.pointerEvents = 'none';

		await window.app.handlers.session.setChoice(0, this.objectId).then(response => {
			if (response.wtf >= 4) {
				Utils.DispatchEvent.dispatch(Events.tndr.wtf, {
				});
			}
		});
	}

	_onClickYes(evt, element) {
		let profile = document.querySelector('.profile[data-id="'+this.objectId+'"]');
		profile.dataset.choice = 'yes';
		document.querySelector('.user-overlay').style.display = 'none';
		//document.querySelector('.users__item[data-id="'+this.objectId+'"]').style.pointerEvents = 'none';

		window.app.handlers.session.setChoice(1, this.objectId).then(() => {
			Utils.DispatchEvent.dispatch(Events.tndr.match, {
				userid: this.objectId
			});
		});
	}
}

export default Profile;