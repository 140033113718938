/**
 * project: General
 * Created by EBiermann on 01.10.2019.
 */

import Events from "../tndr.events";
import * as Utils from "../utilities/Utilities";

class MutationHandler {
	constructor(watchElement) {
		this._watchElement = watchElement;
		this._observer = null;

		this._init();

	}

	//public

	attachObserver() {
		this._attachObserver();
	}

	detachObserver() {
		this._detachObserver();
	}

	getInstance() {
		return this;
	}

	//private

	_init() {
		this._observer = new MutationObserver(this._onMutationOberserved.bind(self));
		this._attachObserver();
	}

	_onMutationOberserved(mutations) {
		try {
			Utils.DispatchEvent.dispatch(Events.layout.mutation.oberserved, {
				mutations: mutations
			})
		}
		catch (e) {
			console.error(e);
		}
	}

	_attachObserver() {
		if (this._observer) {
			try {
				this._observer.observe(this._watchElement, {
					childList: true,
					subtree: true,
					attributes: false,
					characterData: false
				});
			}
			catch (e) {
				console.error(e);
			}
		}
	}

	_detachObserver() {
		if (this._observer) {
			try {
				this._observer.disconnect();
			}
			catch (e) {
				console.error(e);
			}
		}
	}
}

export default MutationHandler;