/**
 * project: General
 * Created by EBiermann on 22.10.2019.
 */
"use strict";

import ResizeObserver from 'resize-observer-polyfill';
import Events from "../tndr.events";
import * as Utils from "../utilities/Utilities";

class ResizeHandler {
	constructor() {
		this._observer = null;
		this._items = [];
		this._callbacks = [];

		this._init();
	}
	//public

	attach(element, callback) {
		console.log("attach to resize handler", element);
		this._items.push(element);
		this._callbacks.push(callback);
		this._observer.observe(element);
	}

	detach(element) {
		const idx = this._items.indexOf(element);
		if (idx > -1) {
			this._items.splice(idx, 1);
			this._callbacks.splice(idx, 1);
		}

		this._observer.unobserve(element);
	}

	getInstance() {
		return this;
	}

	//private
	_init() {
		this._observer = new ResizeObserver(this._onResizeObserved.bind(this));
	}

	_onResizeObserved(resizeObserverEntries) {
		let target, idx;
		for (let entry of resizeObserverEntries) {
			const {left, top, width, height} = entry.contentRect;
			target = entry.target;
			idx = this._items.indexOf(target);

			if (idx !== -1) {
				if (this._callbacks[idx]) {
					this._callbacks[idx]( width, height);
				}
			}

			//console.log('Element:', entry.target);
			//console.log(`Element's size: ${ width }px x ${ height }px`);
			//console.log(`Element's paddings: ${ top }px ; ${ left }px`);

			//if(entry.contentBoxSize) {
			//	h1Elem.style.fontSize = Math.max(1.5, entry.contentBoxSize.inlineSize/200) + 'rem';
			//	pElem.style.fontSize = Math.max(1, entry.contentBoxSize.inlineSize/600) + 'rem';
			//} else {
			//	h1Elem.style.fontSize = Math.max(1.5, entry.contentRect.width/200) + 'rem';
			//	pElem.style.fontSize = Math.max(1, entry.contentRect.width/600) + 'rem';
			//}
		}
	}
}

export default ResizeHandler;