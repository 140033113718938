/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 15.01.2020.
 */

/**
 * Component definitions
 * Properties:
 * - classname: Real JS classname (export)
 * - selector: html dom selector for "autloading"
 * - loadInPimcoreEditmode: some components should not be loaded in pimcore backend, you can exlude it from "autoloading"
 *
 */
const Config = {
	components: [
		//{ classname: 'Header', selector: '.header', loadInPimcoreEditmode: false},
		{ classname: 'Intro', selector: '.intro', loadInPimcoreEditmode: false},
		{ classname: 'Screens', selector: '.app__screens', loadInPimcoreEditmode: true},
		{ classname: 'Alert', selector: '.alert', loadInPimcoreEditmode: false},
		{ classname: 'Toolbar', selector: '.toolbar', loadInPimcoreEditmode: true},
		{ classname: 'Picture', selector: '.picture', loadInPimcoreEditmode: true},
		{ classname: 'UserItem', selector: '.users__item', loadInPimcoreEditmode: true},
		{ classname: 'Profile', selector: '.profile--overlay', loadInPimcoreEditmode: true},
		{ classname: 'Messages', selector: '.message-list', loadInPimcoreEditmode: true},
	]
}

export default Config;