/**
 * project: 0600_katy-tndr
 * Created by EBiermann on 04.05.2021.
 */

"use strict";

import {gsap} from "gsap/all";
import delegate from "es6-delegate/delegate";

import Events from "../tndr.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class Picture extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this.activeImage = this._element.querySelector('.picture__image--active');
		this.images = this._element.querySelectorAll('.picture__image');
		this.controlLeft = this._element.querySelector('.picture__control--left');
		this.controlRight = this._element.querySelector('.picture__control--right');
		this.pagination = this._element.querySelector('.pagination');

		this._tl = null;
		this._init();

	}

	//public

	//private
	_init() {
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		delegate(this._element, 'click', '.picture__control--left', this._onClickLeft.bind(this));
		delegate(this._element, 'click', '.picture__control--right', this._onClickRight.bind(this));
	}

	_onClickLeft(evt) {
		evt.preventDefault();
		if (this.activeImage && this.images.length > 1) {
			let currentIndex = parseInt(this.activeImage.dataset.index);
			let nextIndex = currentIndex - 1;
			if (nextIndex < 0) {
				nextIndex = this.images.length - 1;
			}

			let nextImage = this._element.querySelector('.picture__image[data-index="'+nextIndex+'"]');
			if (nextImage) {
				nextImage.classList.add('picture__image--active');
				this.activeImage.classList.remove('picture__image--active');
				this.activeImage = nextImage;

				if (this.pagination) {
					this.pagination.querySelector('.pagination__item--active').classList.remove('pagination__item--active');
					this.pagination.querySelector('.pagination__item[data-index="'+nextIndex+'"]').classList.add('pagination__item--active');
				}
			}
		}

	}
	_onClickRight(evt) {
		evt.preventDefault();
		if (this.activeImage && this.images.length > 1) {
			let currentIndex = parseInt(this.activeImage.dataset.index);
			let nextIndex = currentIndex + 1;
			if (nextIndex > (this.images.length - 1)) {
				nextIndex = 0;
			}

			let nextImage = this._element.querySelector('.picture__image[data-index="'+nextIndex+'"]');
			if (nextImage) {
				nextImage.classList.add('picture__image--active');
				this.activeImage.classList.remove('picture__image--active');
				this.activeImage = nextImage;

				if (this.pagination) {
					this.pagination.querySelector('.pagination__item--active').classList.remove('pagination__item--active');
					this.pagination.querySelector('.pagination__item[data-index="'+nextIndex+'"]').classList.add('pagination__item--active');
				}
			}
		}
	}
}

export default Picture;