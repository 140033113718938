/**
 * project: 0600_katy-tndr
 * Created by EBiermann on 04.05.2021.
 */

"use strict";

import {gsap} from "gsap/all";
import delegate from "es6-delegate/delegate";

import Events from "../tndr.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class UserItem extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this.userOverlay = document.querySelector('.user-overlay');
		this.userContent = this.userOverlay.querySelector('.user-overlay__content');

		this._init();

	}

	//public

	//private
	_init() {

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		this._element.addEventListener('click', this._onClick.bind(this));
		//delegate('click', '[data-action="alert"][data-type="'+ this.type +'"]', this._onClick.bind(this));
		//window.addEventListener(Events.layout.resize.did, this._onResize.bind(this));
		//window.addEventListener(Events.layout.mutation.oberserved, this._onResize.bind(this));
		//window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
	}

	async _onClick(evt) {
		evt.preventDefault();

		const response = await fetch('app/userprofile/' + parseInt(this._element.dataset.id)+'/'+window.app.handlers.session.getSessionId());
		const userHtml = await response.text();
		this.userContent.innerHTML = userHtml;
		gsap.set(this.userOverlay, { display: 'block'});
		this.userOverlay.scrollTop = 0;

	}

}

export default UserItem;