/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 01.10.2019.
 */

/**
 * Only for code completion ....
 * @type {{layout: {mutation: {oberserved: string}, scroll: {did: string}, resize: {did: string}}, general: {}, preload: {finished: string, begin: string, register: string}}}
 */
const Events = {
	general: {
		scroll: 'throttledScroll',
		resize: 'throttledResize',
		instance: {
			get: 'general:instance:get'
		}
	},
	preload: {
		begin: 'preload:begin',
		finished: 'preload:finished',
		register: 'preload:register',
		unregister: 'preload:unregister',
		xhr: 'preload:xhr'
	},
	layout: {
		dimension: {
			check: 'layout:dimension:check'
		},
		resize: {
			did: 'layout:resize:did'
		},
		scroll: {
			did: 'layout:scroll:did'
		},
		mutation: {
			oberserved: 'layout:mutation:oberserver'
		}
	},
	inview: {
		in: 'inview:in',
		change: 'inview:change',
		out: 'inview:out'
	},
	screens: {
		out: 'screens:out',
		in: 'screens.in'
	},
	tndr: {
		match: 'choice:match',
		wtf: 'wtf:triggered'
	}
}

export default Events;
