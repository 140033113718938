/**
 * project: 0600_katy-tndr
 * Created by EBiermann on 02.05.2021.
 */


"use strict";

import {gsap} from "gsap/all";
import delegate from "es6-delegate/delegate";

import Events from "../../tndr.events";
import * as Utils from "../../utilities/Utilities";
import BaseClass from "../../classes/BaseClass";
import Init from "../../classes/Init";

class Users extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this.index = null;
		this.visible = false;

		this.screenBackground = this._element.querySelectorAll('.screen__background');
		this.usersElement = this._element.querySelector('.users');
		this.userGridItems = null;

		this._init();
	}

	//public
	getInstance() {
		return this;
	}

	isCurrentScreen() {
		return this.visible;
	}

	getIndex() {
		return this.index;
	}

	/**
	 *
	 * @returns {Promise<void>}
	 */
	out() {
		return this._out();
	}


	in() {
		return this._in();
	}

	//private
	_init() {
		this.visible = this._element.classList.contains('screen--visible');
		this.index = this._element.dataset.index;

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		//delegate(this._element, 'click', '.button--toolbar', this._onClick.bind(this));
	}

	async _collectItems() {
		const response = await fetch('/app/users', {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json'
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer',
			body: JSON.stringify({'localstorageId': window.app.handlers.session.getSessionId() })
		});

		const usersHtml = await response.text();

		this.usersElement.innerHTML = usersHtml;
		this.userGridItems = this._element.querySelectorAll('.users__item');
		return usersHtml;
	}

	async _out() {
		await gsap.timeline({
			onComplete: () => {
				if (this.visible) {
					this.visible = false;
					this.element.classList.remove('screen--visible');
				}
			}
		})
			.to(this.screenBackground, {
				opacity:0,
				duration: 0.15
			})
			.to(this.userGridItems, {
				opacity: 0,
				y: 20,
				stagger: { // wrap advanced options in an object
					each: 0.1,
					from: 'end',
					grid: 'auto',
					ease: 'power2.inOut'
					//repeat: -1 // Repeats immediately, not waiting for the other staggered animations to finish
				},
				duration:0.3
			});
	}

	async _in() {
		this.element.scrollTop = 0;
		await this._collectItems();

		gsap.set(this.userGridItems, {opacity: 0, y: 20});

		await gsap.timeline({
			onStart: () => {
				console.log("in this", this.userGridItems);
				if (!this.visible) {
					this.visible = true;
					this.element.classList.add('screen--visible');
				}
			}
		})
			.to(this.screenBackground, {
				opacity:1,
				duration: 0.15
			})
			.to(this.userGridItems, {
				opacity: 1,
				y: 0,
				stagger: { // wrap advanced options in an object
					each: 0.1,
					from: 'start',
					grid: [6,2],
					ease: 'power2.inOut'
					//repeat: -1 // Repeats immediately, not waiting for the other staggered animations to finish
				},
				duration:0.4
			});


	}
}

export default Users;