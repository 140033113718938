/**
 * project: 0600_katy-tndr
 * Created by EBiermann on 06.05.2021.
 */

"use strict";

import {gsap} from "gsap/all";
import delegate from "es6-delegate/delegate";

import Events from "../tndr.events";
import * as Utils from "../utilities/Utilities";
import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";

class Messages extends BaseClass.inherit(Init) {
	constructor(element) {
		super(element);

		this.messageOverlay = document.querySelector('.message-overlay');
		this.messageOverlayContent = this.messageOverlay.querySelector('.message-overlay__content');

		this._init();

	}

	//public

	//private
	_init() {
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		delegate('click', '.message-list__item', this._onClickMessageList.bind(this));
		delegate(this.messageOverlay, 'click', '.message-overlay__close', this._hideMessages.bind(this));

		//delegate('click', '[data-action="alert"][data-type="'+ this.type +'"]', this._onClick.bind(this));
		//window.addEventListener(Events.layout.resize.did, this._onResize.bind(this));
		//window.addEventListener(Events.layout.mutation.oberserved, this._onResize.bind(this));
		//window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
	}

	async _showMessages() {
		let messages = this.messageOverlay.querySelectorAll('.message-overlay__message');
		gsap.set(messages, { opacity: 0, y: 10});
		await gsap.to(messages, {
			opacity: 1,
			y: 0,
			stagger: { // wrap advanced options in an object
				each: 0.1,
				from: 'start',
				ease: 'power2.inOut'
				//repeat: -1 // Repeats immediately, not waiting for the other staggered animations to finish
			},
			duration:0.3
		})

		this.messageOverlay.scrollTop = 0;
	}

	async _hideMessages() {
		await gsap.to(this.messageOverlay, { xPercent: 100, duration: 0.3, ease: 'power2.inOut'});
		gsap.set(this.messageOverlay, { display: 'none'});

	}

	async _onClickMessageList(evt,element) {
		evt.preventDefault();

		const response = await fetch('/app/session/message/' + parseInt(element.dataset.id));
		const html = await response.text();
		this.messageOverlayContent.innerHTML = html;
		gsap.set(this.messageOverlay, { display: 'block', xPercent: 100});
		await gsap.to(this.messageOverlay, { xPercent: 0, duration: 0.3, ease: 'power2.inOut'});

		this._showMessages();
	}

}

export default Messages;