/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 01.10.2019.
 *
 * https://stackoverflow.com/questions/34655616/create-an-instance-of-a-class-in-es6-with-a-dynamic-name
 */
//class ComponentFactory {
//	constructor() {
//
//	}
//}
//
//export default ComponentFactory;

//import ScrollIndicator from "../components/ScrollIndicator";
import Intro from "../components/Intro";
import Alert from '../components/Alert';
import Toolbar from '../components/Toolbar';
import Screens from '../components/Screens';
import Picture from '../components/Picture';
import UserItem from '../components/UserItem';
import Profile from '../components/Profile';
import Messages from '../components/Messages';

const classes = {
	Intro,
	Alert,
	Toolbar,
	Screens,
	Picture,
	UserItem,
	Profile,
	Messages
}

export default function ComponentFactory(name) {
	//console.log("ComponentFactory", name, classes);
	return classes[name];
}